import QuestionAnswer from './QuestionAnswer'
import './styles.scss'

interface FaqProps {
  title: string
  questions: Array<{
    title: string
    content: string
  }>
}

const Faq = ({ title, questions }: FaqProps) => {
  return (
    <div id="duvidas" className="questions-container">
      <h2>{title}</h2>
      <div className="content__questions">
        {questions.map((question, index) => {
          return (
            <QuestionAnswer
              key={index}
              title={question.title}
              content={question.content}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Faq

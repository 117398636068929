/* eslint-disable no-console */
import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'

interface BenefitsCarouselProps {
  title: string
  BenefitsProps?: Array<{
    image?: string
    BenefitsTitle?: string
    BenefitsDescription?: string
    reminderText?: string
    alt?: string
    link?: string
    marginTitle?: string
    marginMobTitle?: string
    widthIcon?: string
    widthMobIcon?: string
    maxWidth?: string
    maxMobWidth?: string
  }>
}

// {
//   "image": "https://decathlonpro.vteximg.com.br/arquivos/cashback-bf.png",
//   "BenefitsTitle": "CASHBACK",
//   "BenefitsDescription": "Receba dinheiro de volta para usar em novas compras",
//   "alt": "cashback",
//   "marginTitle": "38px 0 38px",
//   "marginMobTitle": "20px 0 30px",
//   "widthIcon": "60px",
//   "widthMobIcon": "33.75px",
//   "maxMobWidth": "117px"
// },

const BenefitsCarousel = ({ title, BenefitsProps }: BenefitsCarouselProps) => {
  return (
    <div className="benefits-container">
      <h2>{title}</h2>
      <div className="content-wrapper">
        <Swiper
          className="slick-slide-swiper"
          // slidesPerView={5}
          navigation
          spaceBetween={16}
          breakpoints={{
            1440: {
              slidesPerView: 5,
            },
            1244: {
              slidesPerView: 5,
            },
            1100: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            425: {
              slidesPerView: 2,
            },
            360: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 2,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          modules={[Navigation, Lazy]}
        >
          {' '}
          {BenefitsProps?.map((benefit, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="benefits-content">
                  <img
                    id="desk"
                    style={{ width: `${benefit.widthIcon}` }}
                    src={benefit.image}
                    alt={`${benefit.alt} | Decathlon`}
                  />
                  <img
                    id="mob"
                    style={{ width: `${benefit.widthMobIcon}` }}
                    src={benefit.image}
                    alt={`${benefit.alt} | Decathlon`}
                  />
                  <h2 id="desk" style={{ margin: `${benefit.marginTitle}` }}>
                    {benefit.BenefitsTitle}
                  </h2>
                  <h2 id="mob" style={{ margin: `${benefit.marginMobTitle}` }}>
                    {benefit.BenefitsTitle}
                  </h2>
                  <p id="desk" style={{ maxWidth: benefit.maxWidth }}>
                    {benefit.BenefitsDescription}
                  </p>
                  <p id="mob" style={{ maxWidth: benefit.maxMobWidth }}>
                    {benefit.BenefitsDescription}
                  </p>
                  {benefit.reminderText && (
                    <p className="reminder">{benefit.reminderText}</p>
                  )}
                  {benefit.link && <a href={benefit.link}>saiba mais</a>}
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default BenefitsCarousel

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

const MainBanner = () => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [isShow, setIsShow] = useState(true)

  const targetTime = new Date('11/22/2022 00:00:00')

  useEffect(() => {
    const target = targetTime

    const interval = setInterval(() => {
      const now = new Date()
      const difference = target.getTime() - now.getTime()

      const d = Math.floor(difference / (1000 * 60 * 60 * 24))
      const formatD = d < 10 ? Number(`0${d}`) : d

      setDays(formatD)

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )

      const formatH = h < 10 ? Number(`0${h}`) : h

      setHours(formatH)

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))

      const formatM = m < 10 ? Number(`0${m}`) : m

      setMinutes(formatM)
    }, 1000)

    return () => clearInterval(interval)
  }, [targetTime])

  useEffect(() => {
    const target = targetTime
    const dateNow = new Date()
    const difference = target.getTime() - dateNow.getTime()

    if (difference <= 0) {
      setIsShow(false)
    }
  }, [days, hours, minutes])

  return (
    <>
      <div className="container-banner">
        <div className="logo-wrapper">
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/logo-bf.png"
            alt="Logo black friday | Decathlon"
          />
          <h2>COM ATÉ 50% OFF</h2>
          <a href="https://www.decathlon.com.br/collection?q=949&sort=orders_desc">
            confira
          </a>
        </div>
        <div className="img-wrapper">
          <img
            id="desk"
            src="https://decathlonpro.vteximg.com.br/arquivos/main-banner-desktop-bf.png"
            alt="campaign black friday | Decathlon"
          />
          {isShow && (
            <div className="counter-down-wrapper">
              <p>faltam apenas</p>
              <div className="timer-wrapper">
                <div className="timer-content">
                  <p className="time">{days < 10 ? `0${days}` : days}</p>
                  <p className="description-time">dias</p>
                </div>
                <p className="spacer-timer">:</p>
                <div className="timer-content">
                  <p className="time">{hours < 10 ? `0${hours}` : hours}</p>
                  <p className="description-time">horas</p>
                </div>
                <p className="spacer-timer">:</p>
                <div className="timer-content">
                  <p className="time">
                    {minutes < 10 ? `0${minutes}` : minutes}
                  </p>
                  <p className="description-time">min.</p>
                </div>
              </div>
              <a
                id="mob"
                href="https://www.decathlon.com.br/collection?q=949&sort=orders_desc"
              >
                confira
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="container-banner__mob">
        <div className="wrapper-banner">
          <div className="logo-wrapper">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/logo-bf.png"
              alt="Logo black friday | Decathlon"
            />
            <h2>COM ATÉ 50% OFF</h2>
          </div>
          <div className="content-banner">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/main-banner-mobile-bf.png"
              alt="campaign black friday | Decathlon"
            />

            {isShow && (
              <div className="counter-down-wrapper">
                <p>faltam apenas</p>
                <div className="timer-wrapper">
                  <div className="timer-content">
                    <p className="time">{days < 10 ? `0${days}` : days}</p>
                    <p className="description-time">dias</p>
                  </div>
                  <p className="spacer-timer">:</p>
                  <div className="timer-content">
                    <p className="time">{hours < 10 ? `0${hours}` : hours}</p>
                    <p className="description-time">horas</p>
                  </div>
                  <p className="spacer-timer">:</p>
                  <div className="timer-content">
                    <p className="time">
                      {minutes < 10 ? `0${minutes}` : minutes}
                    </p>
                    <p className="description-time">min.</p>
                  </div>
                </div>
                <a href="https://www.decathlon.com.br/collection?q=949&sort=orders_desc">
                  confira
                </a>
              </div>
            )}
          </div>
          {!isShow && (
            <div className="cta-wrapper">
              <a href="https://www.decathlon.com.br/collection?q=949&sort=orders_desc">
                confira
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MainBanner

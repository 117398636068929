import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'

interface MaisProcuradosProps {
  title: string
  ProductsBf: Array<{
    link: string
    image: string
    bannerTitle: string
    bannerDescription: string
    alt: string
    maxWidth?: string
  }>
}

const MaisProcurados = ({ title, ProductsBf }: MaisProcuradosProps) => {
  return (
    <div className="mais-procurados-container">
      <h2>{title}</h2>
      <div className="content-wrapper">
        <Swiper
          className="slick-slide-swiper"
          spaceBetween={16}
          navigation
          breakpoints={{
            1440: {
              slidesPerView: 5,
            },
            1244: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            425: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          modules={[Navigation, Lazy]}
        >
          {ProductsBf.map((banner, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="mais-procurados-content">
                  <a data-event-categoy="LP-BlueFriday" href={banner.link}>
                    <div className="image-wrapper">
                      <img
                        src={banner.image}
                        alt={`${banner.alt} | Decathlon`}
                      />
                    </div>
                    <div className="text-wrapper">
                      <h2>{banner.bannerTitle}</h2>
                      <p style={{ maxWidth: banner.maxWidth }}>
                        {banner.bannerDescription}
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default MaisProcurados

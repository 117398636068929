import { useCallback, useState } from 'react'

interface Props {
  title: string
  content: string
}

function QuestionAnswer(props: Props) {
  const [setActive, setActiveState] = useState('')
  const [openPanel, setOpenPanel] = useState(false)

  const toggleAccordion = useCallback(() => {
    setActiveState(setActive === '' ? 'active' : '')
    setOpenPanel(!openPanel)
  }, [openPanel, setActive])

  return (
    <>
      <div
        className="header-question"
        onClick={toggleAccordion}
        onKeyPress={toggleAccordion}
        role="button"
        tabIndex={0}
      >
        <button className="pergunta">{props.title}</button>
        {!openPanel ? (
          <div className="arrow-wrapper">
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.9348 0.936919C16.3498 0.351919 15.4048 0.351919 14.8198 0.936919L8.99985 6.75692L3.17984 0.936919C2.59484 0.351919 1.64984 0.351919 1.06484 0.936919C0.479843 1.52192 0.479843 2.46692 1.06484 3.05192L7.94985 9.93692C8.53485 10.5219 9.47985 10.5219 10.0648 9.93692L16.9498 3.05192C17.5198 2.48192 17.5198 1.52192 16.9348 0.936919Z"
                fill="#0082C3"
              />
            </svg>
          </div>
        ) : (
          <div className="arrow-wrapper">
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.06516 10.0641C1.65016 10.6491 2.59516 10.6491 3.18016 10.0641L9.00015 4.24406L14.8202 10.0641C15.4052 10.6491 16.3502 10.6491 16.9352 10.0641C17.5202 9.47906 17.5202 8.53406 16.9352 7.94906L10.0502 1.06406C9.46515 0.479058 8.52015 0.479058 7.93515 1.06406L1.05015 7.94906C0.480155 8.51906 0.480155 9.47906 1.06516 10.0641Z"
                fill="#0082C3"
              />
            </svg>
          </div>
        )}
      </div>
      {openPanel && (
        <div className="panel">
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      )}
    </>
  )
}

export default QuestionAnswer

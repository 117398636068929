const PurchaseListSection = () => {
  return (
    <div className="purchase-container">
      <div className="left-content">
        <h2>Lista de compras</h2>
        <p id="desk">
          Favorite seus produtos preferidos, crie sua lista de compras e fique
          ligado nas ofertas
        </p>
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/app-blackfriday-1.png"
          alt="Aplicativo | Decathlon"
          id="desk"
        />
        <div id="mob" className="text-image">
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/app-blackfriday-1.png"
            alt="Aplicativo | Decathlon"
          />
          <p>
            Favorite seus produtos preferidos, crie sua lista de compras e fique
            ligado nas ofertas
          </p>
        </div>
        <a href="https://www.decathlon.com.br/aplicativo">Baixe o app</a>
      </div>
      <div className="right-content">
        <div className="content-wrapper">
          <div className="text-content">
            <h2>
              Baixe agora
              <br id="desk" /> e aproveite!
            </h2>
            {/* <h2>Ganhe 10% off na sua primeira compra no app!</h2>
            <span>*Consulte o regulamento</span>
            <p>Baixe agora e aproveite!</p> */}
          </div>
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/qr-code-bf.png"
            alt="qr code | Decathlon"
          />
        </div>
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/app-blackfriday-2.png"
          alt="Aplicativo | Decathlon"
        />
      </div>
    </div>
  )
}

export default PurchaseListSection

/* eslint-disable no-console */
import { Input } from '@faststore/ui'
import InputMask from 'react-input-mask'

interface InputFormProps {
  mask?: string | Array<string | RegExp>
  placeholder?: string
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label: string
  error?: string
}

const InputForm = ({
  mask,
  placeholder,
  value,
  onChange,
  label,
  error,
}: InputFormProps) => {
  return (
    <div className="float-label">
      {mask ? (
        <>
          <InputMask
            value={value}
            mask={mask ?? ''}
            placeholder={placeholder}
            onChange={onChange}
            required
            className={error ? 'error' : ''}
          />
          {error && (
            <p className={`errorInput ${error ? 'error-label' : ''}`}>
              {error}
            </p>
          )}
        </>
      ) : (
        <>
          <Input
            className={error ? 'error' : ''}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
          {error && (
            <p className={`errorInput ${error ? 'error-label' : ''}`}>
              {error}
            </p>
          )}
        </>
      )}
      <label className={error ? 'error-label' : ''} htmlFor="text">
        {label}
      </label>
    </div>
  )
}

export default InputForm

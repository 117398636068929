import CloseModalIcon from './CloseModalIcon'

interface ModalNotificationProps {
  onClick: () => void
  error: boolean
}

const ModalNotification = ({ onClick, error }: ModalNotificationProps) => {
  return (
    <div className="modal-container">
      <div className="backdrop">
        {error ? (
          <>
            <CloseModalIcon onClick={onClick} />
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/error-bf.png"
              alt="cadastro já existente | Decathlon"
            />
            <h2>Cadastro já realizado!</h2>
            <p>
              Você já possui cadastro com o e-mail informado. <br id="desk" />
              <br /> Em breve você receberá as melhores ofertas da Blue Friday!
            </p>
            {/* <p>Em breve você receberá as melhores ofertas da Blue Friday!</p> */}
          </>
        ) : (
          <>
            <CloseModalIcon onClick={onClick} />
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/success-bf.png"
              alt="cadastro realizado | Decatthlon"
            />
            <h2>Recebido!</h2>
            <p>
              Recebemos os seus dados! <br />
              <br id="mob" /> Pronto, em breve você receberá as melhores ofertas
              da Blue Friday!
              <br />
              <br />
              Confira seu e-mail e confirme seu cadastro para ter acesso à sua
              conta Decathlon!
            </p>
            {/* <p>
              Confira seu e-mail e confirme seu cadastro para ter acesso à sua
              conta Decathlon!
            </p> */}
          </>
        )}
      </div>
    </div>
  )
}

export default ModalNotification

import { useEffect, useState } from 'react'
import { validateCPF, validateEmail, validatePhone } from 'validations-br'
import { addClubDecathlonUser } from 'src/hooks/useAddClubDecathlonUser'

import InputForm from './InputForm'
import ModalNotification from './ModalNotification'

const FormBlackFriday = () => {
  const [inputName, setInputName] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputPhone, setInputPhone] = useState('')
  const [inputCpf, setInputCpf] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>(true)

  const [isValidPhone, setIsValidPhone] = useState<boolean | undefined>(true)

  const [isValidCpf, setIsValidCpf] = useState<boolean | undefined>(true)
  const [isEmptyInput, setIsEmptyInput] = useState<boolean | undefined>(false)
  const [emailInUse, setEmailInUse] = useState(false)

  const validadeInputs = () => {
    setIsValidEmail(validateEmail(inputEmail))
    setIsValidPhone(validatePhone(inputPhone))
    setIsValidCpf(validateCPF(inputCpf))
    !inputName ? setIsEmptyInput(true) : setIsEmptyInput(false)
  }

  const handleNameInput = (name: string) => {
    setInputName(name)
    !inputName ? setIsEmptyInput(true) : setIsEmptyInput(false)
  }

  const clearInputs = () => {
    setInputName('')
    setInputEmail('')
    setInputPhone('')
    setInputCpf('')
  }

  useEffect(() => {
    if (inputCpf) {
      setIsValidCpf(validateCPF(inputCpf))
    }

    if (inputEmail) {
      setIsValidEmail(validateEmail(inputEmail))
    }

    if (inputPhone) {
      setIsValidPhone(validatePhone(inputPhone))
    }
  }, [inputCpf, inputPhone, inputEmail])

  const sumbitForm = async (): Promise<void> => {
    validadeInputs()

    if (
      !isValidCpf ||
      !inputCpf ||
      !isValidPhone ||
      !inputPhone ||
      !isValidEmail ||
      !inputEmail ||
      !inputName ||
      isEmptyInput
    ) {
      return
    }

    const data = await addClubDecathlonUser({
      email: inputEmail,
      mobile: inputPhone,
      nationalId: inputCpf,
      decathlon: true,
      partner: true,
    })

    if (data.addUserV2 === null) {
      setEmailInUse(true)
      setIsOpen(true)
      clearInputs()

      return
    }

    setEmailInUse(false)
    setIsOpen(true)
    clearInputs()
  }

  return (
    <>
      {isOpen && (
        <ModalNotification
          error={emailInUse}
          onClick={() => setIsOpen(false)}
        />
      )}

      <div className="form-container">
        <div className="form-header">
          <h2>Quer receber as ofertas em primeira mão?</h2>
          <p>
            Cadastre-se e seja informado sobre as ofertas da Blue Friday, além
            de aproveitar os benefícios exclusivos do Clube Decathlon
          </p>
        </div>
        <div className="form-wrapper">
          <div className="form-content">
            <InputForm
              value={inputName}
              onChange={(e) => handleNameInput(e.target.value)}
              label="Nome"
              placeholder="Nome e Sobrenome"
              error={
                isEmptyInput && !inputName ? 'Campo obrigatório' : undefined
              }
            />
            <InputForm
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              label="E-mail"
              placeholder="cliente@email.com"
              error={
                !inputEmail && !isValidEmail
                  ? 'Campo obrigatório'
                  : !isValidEmail
                  ? 'Inserir um e-mail válido'
                  : undefined
              }
            />
            <InputForm
              value={inputCpf}
              onChange={(e) => setInputCpf(e.target.value)}
              label="CPF"
              mask="999.999.999-99"
              placeholder="000.000.000-00"
              error={
                !inputCpf && !isValidCpf
                  ? 'Campo obrigatório'
                  : !isValidCpf
                  ? 'Inserir um CPF válido'
                  : undefined
              }
            />
            <InputForm
              value={inputPhone}
              onChange={(e) => setInputPhone(e.target.value)}
              label="Celular"
              mask="(99) 99999-9999"
              placeholder="(00) 00000-0000"
              error={
                !inputPhone && !isValidPhone
                  ? 'Campo obrigatório'
                  : !isValidPhone
                  ? 'Telefone inválido'
                  : undefined
              }
            />
          </div>
          <div className="submit-container">
            <p>
              *Ao clicar em enviar, declaro que concordo com a{' '}
              <a href="https://www.decathlon.com.br/servicos/politica-de-privacidade?_ga=2.186753515.290798398.1666181372-1922909307.1666181372">
                política de privacidade
              </a>{' '}
              e desejo fazer parte do Clube Decathlon para receber dicas e
              ofertas exclusivas.
            </p>
            <button onClick={() => sumbitForm()}>enviar</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormBlackFriday

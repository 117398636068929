import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'

interface EnjoyBlackFridayProps {
  title: string
  tipsBf: Array<{
    tipsTitle: string
    tipsDescription: string
    maxWidth?: string
    border?: boolean
  }>
}

const EnjoyBlackFriday = ({ title, tipsBf }: EnjoyBlackFridayProps) => {
  return (
    <div className="enjoy-container">
      <h2>{title}</h2>
      <div className="content-wrapper">
        <Swiper
          className="slick-slide-swiper"
          slidesPerView={1}
          spaceBetween={16}
          navigation
          breakpoints={{
            1440: {
              slidesPerView: 4,
            },
            1244: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 2,
            },
            425: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 2,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          modules={[Navigation, Lazy]}
        >
          {tipsBf.map((tip, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="enjoy-content">
                  <div className="text-wrapper">
                    <h2 className={`${tip.border ? 'border' : ''}`}>
                      {tip.tipsTitle}
                    </h2>
                    <p style={{ maxWidth: tip.maxWidth }}>
                      {tip.tipsDescription}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default EnjoyBlackFriday

/* eslint-disable prettier/prettier */
import { gql } from '@vtex/graphql-utils'
import { request } from 'src/sdk/graphql/request'
import type {
  AddUserV2Mutation,
  AddUserV2MutationVariables,
} from '@generated/graphql'

const query = gql`
  mutation addUserV2(
    $email: String!
    $mobile: String!
    $nationalId: String!
    $decathlon: Boolean!
    $partner: Boolean
  ) {
    addUserV2(
      email: $email
      mobile: $mobile
      nationalId: $nationalId
      decathlon: $decathlon
      partner: $partner
    )
  }
`

export function addClubDecathlonUser({
  email,
  mobile,
  nationalId,
  decathlon,
  partner,
}: AddUserV2MutationVariables) {
  return request<AddUserV2Mutation, AddUserV2MutationVariables>(query, {
    email,
    mobile,
    nationalId,
    decathlon,
    partner,
  })
}

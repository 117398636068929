interface CloseModalProps {
  onClick: () => void
}

const CloseModal = ({ onClick }: CloseModalProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12.8181 9.56883L22.0265 0.60144L24.6569 3.16303L15.4485 12.1304L24.6569 21.0978L22.0265 23.6594L12.8181 14.692L3.60979 23.6594L0.97937 21.0978L10.1877 12.1304L0.97937 3.16303L3.60979 0.60144L12.8181 9.56883Z"
        fill="black"
      />
    </svg>
  )
}

export default CloseModal

const MidiasSection = () => {
  return (
    <div className="midias-container">
      <a href="https://www.facebook.com/decathlonbrasil/">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/face-bf.png"
          alt="Facebook | Decathlon"
        />
      </a>
      <a href="https://www.instagram.com/decathlonbrasil/">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/instagram-bf.png"
          alt="Instagram | Decathlon"
        />
      </a>
      <a href="https://www.youtube.com/c/DecathlonBrasilOficial">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/youtube-bf.png"
          alt="YouTube | Decathlon"
        />
      </a>
    </div>
  )
}

export default MidiasSection

import Faq from 'src/components/shared/Faq'

import dataTips from './tips.json'
import dataFaq from './faq.json'
import dataProductsSearch from './dataProductsSearch.json'
import dataBenefits from './dataBenefits.json'
import EnjoyBlackFriday from './EnjoyBlackFriday'
import FormBlackFriday from './FormBlackFriday'
import MainBanner from './MainBanner'
import MaisProcurados from './MaisProcurados'
import PurchaseListSection from './PurchaseListSection'
import MidiasSection from './MidiasSection'
import Benefits from './BenefitsCarousel'

import './styles.scss'

const BlackFriday = () => {
  return (
    <main className="black-friday-22">
      <div className="space-banner">
        <MainBanner />
      </div>
      <FormBlackFriday />
      <MaisProcurados
        title="Mais procurados da Blue Friday"
        ProductsBf={dataProductsSearch}
      />
      <EnjoyBlackFriday
        title="Como aproveitar a Blue Friday?"
        tipsBf={dataTips}
      />
      <PurchaseListSection />
      <Benefits title="Benefícios" BenefitsProps={dataBenefits} />
      <section className="faq-black-friday">
        <Faq title="FAQ" questions={dataFaq} />
      </section>
      <MidiasSection />
    </main>
  )
}

export default BlackFriday
